<script setup>
import { computed } from "vue"

const props = defineProps({
  cols: {
    type: String,
    default: "",
  },
})

const columnClass = computed(() => {
  return props.cols ? "col-" + props.cols : "col"
})
</script>

<template>
  <div :class="columnClass" class="field flex flex-column gap-1 align-self-baseline">
    <slot />
  </div>
</template>
